var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4KZSWmYR22xpLgN2YDbur"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/partner";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { SENTRY_DSN, ignoreErrors } from "./src/observability/sentryConfig";
import { censorSensitiveInformation } from "./src/observability/censorSensitiveInformation";
import { isValidBrowser } from "./src/observability/isValidBrowser";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://3db850a1b1f44980bf961daf95dc5179@o23399.ingest.sentry.io/5718454",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  ignoreErrors,
  beforeSend(event) {
    const userAgent = window.navigator.userAgent;

    if (!isValidBrowser(userAgent)) {
      return null;
    }

    censorSensitiveInformation(event);
    return event;
  },
});
